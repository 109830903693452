<template>
  <div class="content">
    <div class="video" v-for="(item,xh) in videoList" :key="xh">
      <video :src="item.playurl" controls muted preload></video>
    </div>
  </div>
</template>
<script>
export default {
    name: 'mycode',
    components: {},
    data() {
        return {
            open: false,
            videoList: []
        }
    },
    mounted() {
        this.getVideo()

    },
    watch: {
        $route() {
        }
    },
    methods: {
        getVideo() {
            fetch('https://api.apiopen.top/api/getMiniVideo').then((rs) => {
                return rs.json()
            }).then((data) => {
                this.videoList = data.result.list
                //可视区域播放视频
                this.$nextTick(() => {
                    const video = document.querySelectorAll("video")
                    const observer = new IntersectionObserver(nodes => {
                        nodes.forEach(v => {
                            if (v.isIntersecting) { // 判断是否进入可视区域
                                v.target.muted=false
                                v.target.play();
                            } else {
                                v.target.pause();
                            }
                        });
                    });
                    video.forEach(v => observer.observe(v));
                })
            })
        }
    }
}
</script>
